.quiz__heading {
    font-size: 1.5em;
    text-align: center;
}

.quiz__answers {
    font-size: 1.35em;
    list-style: none;
    margin: 0;
    padding: 0 1em;
}

.quiz__answerbox {
    margin-bottom: 1em;
}

.quiz__answer {
    display: block;
    text-align: left;
    width: 100%;
}

.quiz__answer[disabled] {
    cursor: not-allowed;
}

.quiz__answer--correct,
.quiz__answer--incorrect {
    background-position: 0 calc(50% + 6px); /* Compensate for extra space in SVG */
    background-repeat: no-repeat;
    background-size: auto 2em;
    display: flex;
    padding-left: 2.2em;
}

.quiz__answer--correct {
    background-image: url('./images/quiz-answer-correct.svg');
    color: #029922;
}

.quiz__answer--incorrect {
    background-image: url('./images/quiz-answer-incorrect.svg');
    background-position-x: -6px; /* Compensate for extra space in SVG */
    color: #dc0024;
}

@media (min-width: 768px) {
    .quiz__answers {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .quiz__answerbox {
        flex: 0 1 50%;
        padding: 0 0.25em;
    }
}
