.reward {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    font-size: 1.3em;
    justify-content: stretch;
}

.reward__preamble {
    margin: 0 auto;
    max-width: 500px;
    padding: 0.5em;
}

.reward__frame {
    background: #fff;
    border: 2px solid #000;
    border-radius: 0.35em;
    box-shadow: 0 2px 0.35em rgba(0, 0, 0, 0.4);
    margin: 0.5em auto;
    max-width: 90%;
    padding: 0.5em;
    width: 500px;
}

.reward__imagebox {
    align-items: center;
    display: flex;
    justify-content: center;
}

.reward__image {
    display: inline-block;
    max-width: 100%;
}

.reward__videobox {
    height: 0;
    max-width: 100%;
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
}

.reward__video {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.reward__children {
    padding: 0.5em;
}
