.introduction {
    padding: 1em;
}

.introduction__heading {
    background: url('./images/logo.png') no-repeat center center transparent;
    background-size: contain;
    margin: 0 auto;
    max-width: 546px;
    padding-top: 15%;
}
