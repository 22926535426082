*,
*:before,
*:after {
    box-sizing: border-box;
}

body {
    background: #03783a;
    color: #fff;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    padding: 0;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

a,
a:link,
a:visited {
    color: #fff;
}

.canvas {
    background-color: #000;
    background-image: url('./images/flurry-left.png'),
        url('./images/flurry-right.png'),
        linear-gradient(to right, #03783a, #06b447 50%, #03783a);
    background-position: -80px top, calc(100vw - 130px) top, center top;
    background-repeat: repeat-y, repeat-y, no-repeat;
    min-height: 100vh;
    padding: 1em;
}

.btn {
    appearance: none;
    background: #fff;
    border: 3px solid #dc0024;
    border-radius: 0.5em;
    box-shadow: 0 2px 0.35em rgba(0, 0, 0, 0.4);
    color: #dc0024;
    cursor: pointer;
    font-family: inherit;
    font-size: inherit;
    font-weight: bold;
    letter-spacing: 2px;
    line-height: 1.3;
    padding: 0.15em 0.45em;
    text-transform: uppercase;
}

.btn--primary {
    background: #dc0024;
    border-color: #fff;
    color: #fff;
    display: block;
    font-size: 1.25em;
    margin: 0 auto 1em;
    padding: 0.5em 1.5em;
}

.visuallyhidden {
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    width: 1px;
    overflow: hidden;
    position: absolute !important;
}

@media (min-width: 768px) {
    .canvas {
        background-position: left top, right top, center top;
        padding-left: 100px;
        padding-right: 100px;
    }
}
